import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import StyledBox from "../../components/StyledBox";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";

import {Route} from "react-router-dom";
import {Snackbar, Switch} from "@mui/material";
import {useDispatch} from "react-redux";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import server from "../../server";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import ToolBar from "../../components/ToolBar"
import ServiceAndUser from "./serviceAndUser";
import ExportWarehouse from '../modal/ExportWarehouseModal'
import CreateWarehouse from './warehouse/Dialog/createWarehouse'
import {GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditFormDialog from "./warehouse/Dialog/EditDialog";
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";
import Alert from "@mui/material/Alert";

const Warehouse = "Warehouse";


function WarehousePage() {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchData, SetSearchData] = useState({name: '', address1: '', address2: '', city: '', postal_code: '', state: ''});
    const [totalRows, setTotalRows] = useState(0);
    const dispatch = useDispatch();
    const [Visibility, setVisibility] = useState({
        id: false, user: false, IATA: false
    });
    const [checked, setChecked] = React.useState({

    });
    const handleChange = (event, id, key) => {
        handleSave({[key]: event.target.checked}, id, "PUT", false).then(
            setChecked((prev) => ({
                ...prev,

                [id]: event.target.checked

            }))
        ).catch((error) => {
            setSnackbar({ open: true, message: error.response.message, severity: 'error' });
        })

    };

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const columns = [
        {id: 1, field: "id", headerName: "ID", flex: 5},
        {
            id: 2, field: "warehouse_number", headerName: "仓库编号", flex: 3
        },
        {
            id: 3, field: "name", headerName: "仓库名", flex: 5

        },
        {id: 4, field: "IATA", headerName: "IATA", flex: 5},
        {id: 5, field: "address1", headerName: "地址1", flex: 6},
        {id: 6, field: "address2", headerName: "地址2", flex: 5},
        {id: 7, field: "city", headerName: "城市", flex: 5},
        {id: 8, field: "postal_code", headerName: "邮编", flex: 4},
        {id: 9, field: "state", headerName: "州/省简称", flex: 3},
        {id: 10, field: "phone", headerName: "电话", flex: 5},
        {id: 11, field: "company_name", headerName: "公司名", flex: 5},
        {id: 12, field: "country", headerName: "国家/地区", flex: 5},
        {id: 13, field: "nameToChinese", headerName: "nameToChinese", flex: 3},
        {id: 14, field: "is_specific", headerName: "是否退回美西", flex: 5,
            renderCell: (params) => {
                return <Switch
                    checked={checked[params.row.id]}
                    onChange={(e) => handleChange(e, params.row.id, "is_specific")}
                    inputProps={{'aria-label': 'controlled'}}
                />
            }
        },
        {id: 15, field: "user", headerName: "用户", flex: 5},
        {id: 16, type: 'actions', field: 'actions', headerName: "Actions", getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        title={'Edit'}
                        onClick={() => handleOpenEditDialog(params.row)}
                        key={`edit-${params.id}`}
                        color="primary"
                    />,

                ];
            }},

    ];
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const handleOpenEditDialog = (row) => {
        setSelectedRow(row);
        setOpenEditDialog(true);
    };

    const ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
    }


    const handleSearch = (e) => {
        setLoading(true)
        fetchData("GET", {}, null)
    }
    const Refresh = (open=true) => {
        // setData([]);
        setData([]);
        setLoading(true);
        fetchData('GET', {}, null, {}, open).then(r => {})

    }
    const handleSave = (editedData, rowId, method='PUT', refresh=true) => {
        // 直接返回 fetchData 函数调用的 Promise
        let result = {status: false, message: ''};
        return fetchData(method, editedData, rowId
        ).then((e) => {
            if(refresh){
                Refresh(false)
            }

            result.status = e !== undefined;
            return result;
        }).catch((error) => {
            result.status = false;
            result.message = error.response.message;
            return result;
        }).finally(() => {
            return result;
        });
    }
    const fetchData = async (method, data = {}, rowId = null, sort_data, open=true) => {
        try {
            const queryParams = {
                ...searchData, // 将搜索条件作为查询参数
                ...sort_data,
                curr_page: currentPage < 1 ? 1 : currentPage,
                page_size: pageSize,
            };

            let requestUrl = server.warehouse_list_url;
            let requestData = {};
            let msg = "获取"

            if (method === 'GET') {

                requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);

            } else if (method === 'PUT' && rowId != null) {
                msg = "修改"
                // 对于PUT请求，添加row id到URL，并设置编辑的数据作为请求体
                requestUrl = `${requestUrl}${rowId}/`;
                requestData = data;
            }else if (method === "DELETE") {
                msg = "删除"
                requestUrl = `${requestUrl}${rowId}/`;
            }else if (method === "POST") {
                msg = "创建"
                requestData = data;
            }

            return await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: method,
                data: requestData,
                success: (res) => {
                    setTotalRows(1)


                    if (method === 'GET') {
                        let rows = res.data.rows
                        let check = {}
                        for(let i in rows){
                            check[rows[i].id] = rows[i].is_specific
                        }
                        setChecked(check)
                        setData(res.data.rows);
                        setLoading(false);
                        setTotalRows(res.data.total);
                    }
                    if(open){
                        setSnackbar({ open: true, message: `${msg}成功！`, severity: 'success' });
                    }


                },
                fail: (error) => {
                    console.error('请求失败', error);
                    setData([]);
                    setLoading(false);
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // throw error
                }
            });
        } catch (error) {
            setSnackbar()
            console.error('请求异常', error);
        }
    };

    useEffect(() => {
        setLoading(true)

        // 调用上面定义的函数来加载数据
        fetchData("GET", {}, null, {}, false).then();
    }, [currentPage, pageSize, dispatch]);

    const handleSortModelChange = async (sortModel) => {
        let ordering = '';
        if (sortModel.length > 0) {
            // 处理所有的排序模型，虽然通常只有一个
            ordering = sortModel.map(model => {
                return model.sort === 'asc' ? model.field : `-${model.field}`;
            }).join(',');
        }
        // 构造排序的数据结构
        const sortData = { ordering };
        // 调用 fetchData 函数请求数据
        setLoading(true);
        setData([]);
        await fetchData('GET', {}, null, sortData);
    };
    const onChangeColumnVisibilityModel = (newVisibility) => {
        // if (user.is_open_claim !== "4") {
        //     newVisibility = { ...newVisibility, Check_amount: false };
        // }
        setVisibility(newVisibility);
    }
    return (
        <Box sx={{
            width: '100%'
        }}>
            <ExportWarehouse handleSearch={handleSearch} searchData={searchData} SetSearchData={SetSearchData}></ExportWarehouse>
            <CreateWarehouse onSave={handleSave}></CreateWarehouse>
            <Grid container>
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        {data ? <DataGridPro
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            checkboxSelection={false}
                            headerHeight={70}
                            loading={loading}
                            rowCount={data.length}
                            paginationMode='server'
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: () => <ToolBar onRefresh={Refresh} />,
                            }}
                            onSortModelChange={handleSortModelChange}
                            initialState={
                                {pinnedColumns: {right: ['actions']}}
                            }
                            columnBuffer={3}
                            columnThreshold={3}/> : ''}
                    </StyledBox>
                </Grid>
            </Grid>
            <EditFormDialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                rowData={selectedRow}
                onSave={handleSave}
            />
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default Warehouse;
export {WarehousePage};