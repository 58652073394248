import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import {connect} from "react-redux";
import {revokeClaim} from "../../redux/actions/modal";
import store from "../../redux/store";
import {MODAL_CLOSE_ALERT, VERIFY_CLAIM_OPEN} from "../../redux/type";
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';


class RevokeClaim extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            claim_credit: 0
        }
    }

    Close = () => {
        this.setState({open: false})
    }

    Open = () => {
        let claim_credit = 0
        if (this.props.claim_update_list && this.props.claim_update_list.length >= 1) {
            for (let i = 0; i < this.props.claim_update_list.length; i++) {
                for (let x = 0; x < this.props.data.length; x++) {

                    if (this.props.data[x].id === this.props.claim_update_list[i]) {
                        if (this.props.data[x].status === '0') {
                            store.dispatch({
                                type: VERIFY_CLAIM_OPEN,
                                payload: {level: 'error', title: 'FAILED', message: '无法对未确认的数据进行操作'}
                            })
                            return
                        }else{
                            claim_credit += Number(this.props.data[x].Check_amount)
                            break
                        }
                    }
                }

            }
            this.setState({open: true, claim_credit: claim_credit})
        } else {

            store.dispatch({
                type: VERIFY_CLAIM_OPEN,
                payload: {level: 'error', title: 'FAILED', message: '请勾选一项或多项进行修改'}
            })
            this.setState({open: false})
        }
    }
    handleSubmit = e => {
        e.preventDefault()
        this.props.revokeClaim(this.props.claim_update_list)
        this.setState({open: false})
    }
    closeAlert = () => {
        store.dispatch({type: MODAL_CLOSE_ALERT})
    }

    render() {
        return (
            <span>
                <Button sx={{textTransform: 'none'}} startIcon={<AssignmentReturnIcon/>} onClick={() => {
                    this.Open()
                }}>撤回索赔</Button>
                    <Dialog disableEscapeKeyDown open={this.state.open} onClose={() => {
                        this.Close()
                    }}>
                        <DialogTitle>撤回索赔</DialogTitle>
                        <DialogContent>
                            <Box component="form" onSubmit={this.handleSubmit} sx={{display: 'flex', flexWrap: 'wrap'}}>

                                <FormControl sx={{m: 1, minWidth: 500}}>
                                        即将对此{this.props.claim_update_list.length}个数据进行撤回
                                        <br/><br/><br/>
                                        总撤回额度: {this.state.claim_credit.toFixed(2)}
                                    </FormControl>

                                <DialogActions>
                                    <Button fullWidth variant="contained" sx={{mt: 3, mb: 2, ml: 45}} onClick={() => {
                                        this.Close()
                                    }}>取消</Button>
                                    <Button fullWidth type='submit' variant="contained" sx={{mt: 3, mb: 2}}>确认</Button>
                                </DialogActions>
                            </Box>
                        </DialogContent>
                    </Dialog>
            </span>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    ModalLoading: state.ClaimReducer.ModalLoading,
    isLoading: state.ClaimReducer.isLoading,
    alert: state.ClaimReducer.alert,
    user: state.auth.user,
    data: state.ClaimReducer.data,
    claim_update_list: state.ClaimReducer.claim_update_list
});

export default connect(mapStateToProps, {revokeClaim})(RevokeClaim);