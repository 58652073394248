import React, {useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Snackbar,
} from '@mui/material';
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import {RenderSelectField, RenderTextField} from "../../../components/renderField";
import Paper from '@mui/material/Paper';
import DimensionForm from "./Dimension";
import MenuItem from "@mui/material/MenuItem";
import {AxiosSyncDefaultRequest} from "../../../components/default_request";
import server from "../../../server";
import {useSelector} from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


const CreateFormDialog = ({Refresh, warehouse_service}) => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        Warehouse: '', Name: '', Address1: '', Address2: '', City: '',
        State: '', PostalCode: '', PhoneNumber: '', Reference: '', Email: '', PhoneExtension: ''
    });
    const [test, SetTest] = useState(false)
    const onClose = () => setOpenCreateDialog(false);
    const onClear = () => {
        setFormData({
            Warehouse: '', Name: '', Address1: '', Address2: '', City: '',
            State: '', PostalCode: '', PhoneNumber: '', Reference: '', Email: '', PhoneExtension: ''
        });
        setCards([{
            length: "",
            width: "",
            height: "",
            weight: "",
            subPackageType: "",
            goodsName: "",
            qty: 1
        }]);
    };
    const onOpen = () => setOpenCreateDialog(true);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const [cards, setCards] = useState([{
        length: "",
        width: "",
        height: "",
        weight: "",
        subPackageType: "",
        goodsName: "",
        qty: 1
    }]);
    const user = useSelector((state) => state.auth.user);
    const [statusFormData, setStatusFormData] = useState({
        Name: {required: true, message: "", status: false},
        Address1: {required: true, message: "", status: false},
        City: {required: true, message: "", status: false},
        State: {required: true, message: "", status: false},
        PostalCode: {required: true, message: "", status: false},
        PhoneNumber: {required: true, message: "", status: false},
        Reference: {required: true, message: "", status: false},
        Warehouse: {required: true, message: "", status: false},
        Email: {required: false, message: "", status: false}
    })
    const [dimension_status, SetDimensionStatus] = useState([{
        length: {required: true, message: "", status: false},
        width: {required: true, message: "", status: false},
        height: {required: true, message: "", status: false},
        weight: {required: true, message: "", status: false},
        subPackageType: {required: true, message: "", status: false},
        goodsName: {required: true, message: "", status: false}
    }])
    // const [dimension_helperText, SetDimensionHelper] = useState([{
    //     length: "",
    //     width: "",
    //     height: "",
    //     weight: "",
    //     subPackageType: "",
    //     goodsName: "",
    //
    // }])
    const [subPackagingType, SetType] = useState([[
        {id: 1, type: "BUNDLE"},
        {id: 2, type: "CARTON"},
        {id: 3, type: "CRATE"},
        {id: 4, type: "DRUM"},
        {id: 5, type: "PAIL"},
        {id: 6, type: "PALLET"},
        {id: 7, type: "OTHER"},
        {id: 6, type: "SKID"},
    ]
    ]);
    const addCard = () => {
        if (cards.length >= 10) {
            setSnackbar({open: true, message: '最大添加十个包裹！', severity: 'info'});
            return
        }
        setCards([
            ...cards,
            {
                length: "",
                width: "",
                height: "",
                weight: "",
                subPackageType: "",
                goodsName: "",
                qty: 1
            }
        ]);
        SetDimensionStatus([
            ...dimension_status,
            {
                length: {required: true, message: "", status: true},
                width: {required: true, message: "", status: true},
                height: {required: true, message: "", status: true},
                weight: {required: true, message: "", status: true},
                subPackageType: {required: true, message: "", status: true},
                goodsName: {required: true, message: "", status: true}
            }
        ]);
        SetType(
            [
                ...subPackagingType,

                [
                    {id: 1, type: "BUNDLE"},
                    {id: 2, type: "CARTON"},
                    {id: 3, type: "CRATE"},
                    {id: 4, type: "DRUM"},
                    {id: 5, type: "PAIL"},
                    {id: 6, type: "PALLET"},
                    {id: 7, type: "OTHER"},
                    {id: 6, type: "SKID"},
                ]

            ]
        )
    };
    const deleteCard = index => {
        setCards(cards.filter((card, i) => i !== index));
        SetDimensionStatus(dimension_status.filter((d, i) => i !== index));

    };

    const states = [
        {"id": 1, "abbreviation": "AL"},
        {"id": 2, "abbreviation": "AK"},
        {"id": 3, "abbreviation": "AZ"},
        {"id": 4, "abbreviation": "AR"},
        {"id": 5, "abbreviation": "CA"},
        {"id": 6, "abbreviation": "CO"},
        {"id": 7, "abbreviation": "CT"},
        {"id": 8, "abbreviation": "DE"},
        {"id": 9, "abbreviation": "FL"},
        {"id": 10, "abbreviation": "GA"},
        {"id": 11, "abbreviation": "HI"},
        {"id": 12, "abbreviation": "ID"},
        {"id": 13, "abbreviation": "IL"},
        {"id": 14, "abbreviation": "IN"},
        {"id": 15, "abbreviation": "IA"},
        {"id": 16, "abbreviation": "KS"},
        {"id": 17, "abbreviation": "KY"},
        {"id": 18, "abbreviation": "LA"},
        {"id": 19, "abbreviation": "ME"},
        {"id": 20, "abbreviation": "MD"},
        {"id": 21, "abbreviation": "MA"},
        {"id": 22, "abbreviation": "MI"},
        {"id": 23, "abbreviation": "MN"},
        {"id": 24, "abbreviation": "MS"},
        {"id": 25, "abbreviation": "MO"},
        {"id": 26, "abbreviation": "MT"},
        {"id": 27, "abbreviation": "NE"},
        {"id": 28, "abbreviation": "NV"},
        {"id": 29, "abbreviation": "NH"},
        {"id": 30, "abbreviation": "NJ"},
        {"id": 31, "abbreviation": "NM"},
        {"id": 32, "abbreviation": "NY"},
        {"id": 33, "abbreviation": "NC"},
        {"id": 34, "abbreviation": "ND"},
        {"id": 35, "abbreviation": "OH"},
        {"id": 36, "abbreviation": "OK"},
        {"id": 37, "abbreviation": "OR"},
        {"id": 38, "abbreviation": "PA"},
        {"id": 39, "abbreviation": "RI"},
        {"id": 40, "abbreviation": "SC"},
        {"id": 41, "abbreviation": "SD"},
        {"id": 42, "abbreviation": "TN"},
        {"id": 43, "abbreviation": "TX"},
        {"id": 44, "abbreviation": "UT"},
        {"id": 45, "abbreviation": "VT"},
        {"id": 46, "abbreviation": "VA"},
        {"id": 47, "abbreviation": "WA"},
        {"id": 48, "abbreviation": "WV"},
        {"id": 49, "abbreviation": "WI"},
        {"id": 50, "abbreviation": "WY"},
        {"id": 51, "abbreviation": "DC"}
    ]

    function trimObjectValues(obj) {
        return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [
                key,
                typeof value === 'string' ? value.trim() : value
            ])
        );
    }


    const handleSaves = async () => {
        let new_statusdata = {}
        for (let key in statusFormData) {
            if (key !== "Email") {
                new_statusdata[key] = {required: true, message: "", status: false}
            } else {
                new_statusdata[key] = {required: false, message: "", status: false}
            }

        }
        ;
        setStatusFormData(new_statusdata)

        cards.forEach(function (dimension, index) {
            Object.entries(dimension).forEach(([key, value]) => {
                SetDimensionStatus(prevData => {
                    const newData = [...prevData];
                    newData[index] = {
                        ...newData[index],
                        [key]: {required: true, message: "", status: false}
                    };
                    return newData;
                });
            });
        });

        // 判断cards是否全部填写
        let is_write = true;
        cards.forEach(function (dimension, index) {
            Object.entries(dimension).forEach(([key, value]) => {
                if (!value) {
                    is_write = false
                }
            });
        });
        if (formData.Name && formData.City && formData.Address1 && formData.State && formData.PostalCode && formData.PhoneNumber && formData.Reference
            && formData.Warehouse && is_write
        ) {

            let data = JSON.parse(JSON.stringify(formData));
            // 去除前后空格
            data = trimObjectValues(data)
            if (!isValidUSZipCode(data.PostalCode)) {
                setStatusFormData((prev) => ({
                    ...prev,
                    PostalCode: {required: true, message: "请输入一个正确的邮编", status: true},
                }));
                return null;
            }
            if (!isValidUSTelephoneNumber(data.PhoneNumber)) {
                setStatusFormData((prev) => ({
                    ...prev,
                    PhoneNumber: {required: true, message: "请输入一个正确的PhoneNumber", status: true},
                }));
                return
            }
            if (!data.Email) {
                data["Email"] = "fba@bloomsung.com"
            } else {
                if (!validateEmail(data["Email"])) {
                    setStatusFormData((prev) => ({
                        ...prev,
                        Email: {required: false, message: "请输入一个正确的email", status: true},
                    }));
                    return null;
                }
            }
            setLoading(true);
            let dimension = []
            // 去除前后空格
            for (let index in cards) {
                dimension.push(trimObjectValues(cards[index]))
            }
            data["Dimension"] = dimension
            data["MID"] = user.UID
            data["Service"] = warehouse_service[0].service_number;
            data["ImageType"] = "PDF";

            try {
                await AxiosSyncDefaultRequest({
                    url: server.freight_ship,
                    method: 'POST',
                    data: data,
                    headers: {"WEB-USER": user.username},
                    success: (res) => {
                        if (res.data.status) {
                            setSnackbar({open: true, message: '下单成功！', severity: 'success'});
                            setCards([{
                                length: "",
                                width: "",
                                height: "",
                                weight: "",
                                subPackageType: "",
                                goodsName: "",
                                qty: 1
                            }]);
                            setFormData({
                                Warehouse: '', Name: '', Address1: '', Address2: '', City: '',
                                State: '', PostalCode: '', PhoneNumber: '', Reference: ''
                            })
                            Refresh(false);
                        } else {
                            setSnackbar({open: true, message: res.data.message, severity: 'error'});
                        }

                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error("保存失败", error);
                // 操作失败，显示错误消息
                setSnackbar({open: true, message: error.message, severity: 'error'});
            } finally {
                setLoading(false);
                onClose(); // 保存操作完成后关闭对话框
            }
        } else {
            const newErrors = {};
            for (let field in statusFormData) {
                if (statusFormData[field].required && !formData[field]) {
                    newErrors[field] = {
                        ...statusFormData[field],
                        message: 'This field is required',
                        status: true
                    };
                } else {
                    newErrors[field] = {
                        ...statusFormData[field],
                        message: '',
                        status: false
                    };
                }
            }
            setStatusFormData({
                ...statusFormData,
                ...newErrors
            });
            cards.forEach(function (dimension, index) {
                Object.entries(dimension).forEach(([key, value]) => {
                    if (!value) {
                        SetDimensionStatus(prevData => {
                            const newData = [...prevData];
                            newData[index] = {
                                ...newData[index],
                                [key]: {required: true, message: "This field is required", status: true}
                            };
                            return newData;
                        });
                    }
                });
            });

        }
    };
    const handleChange = (e, value, key, value_name) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value?value[value_name]:''
        }));
    }
    const handleFieldChange = (fieldName) => (event) => {
        const newValue = event.target.value;
        setFormData((prev) => ({
            ...prev,
            [fieldName]: newValue,
        }));
    };

    function validateEmail(email) {
        const re = /^(([^<>()$$$$\\.,;:\s@"]+(\.[^<>()$$$$\\.,;:\s@"]+)*)|(".+"))@(($$[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function isValidUSZipCode(zipCode) {
        // 正则表达式匹配5位数字，或5位数字后跟连字符和4位数字
        const zipCodeRegex = /^\d{5}(-\d{4})?$/;
        return zipCodeRegex.test(zipCode);
    }

    function isValidUSTelephoneNumber(phoneNumber) {
        const phoneRegex = /^(?:\+1\s?)?(?:$\d{3}$|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/;
        return phoneRegex.test(phoneNumber);
    }

    return (
        <>
            <Button sx={{textTransform: 'none'}} startIcon={<AddIcon/>} onClick={() => {
                onOpen()
            }}>
                创建运单
            </Button>
            <Dialog open={openCreateDialog} onClose={onClose} maxWidth="md" fullWidth
                    sx={{width: '100%', minWidth: '100%'}}>
                <DialogTitle>创建运单</DialogTitle>
                <DialogContent>
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid container spacing={1} justifyContent="center">
                            <Grid item xs={3} md={3} lg={3}>
                                <Paper elevation={3} sx={{
                                    padding: 3,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start', // 修改为'flex-start'
                                    alignItems: 'flex-start', // 修改为'flex-start'
                                    mb: 1,
                                    border: '1px solid',
                                    borderColor: 'red',
                                }}>
                                    <Typography sx={{
                                        fontSize: 14,
                                        marginTop: '-15px',
                                        marginLeft: '-15px'
                                    }} color="text.secondary" gutterBottom>
                                        Send Address
                                    </Typography>
                                    {RenderSelectField({
                                        idAname: "warehouse",
                                        label: "Warehouse",
                                        value: formData.Warehouse,
                                        onChangeHandler: (e, value) => {handleChange(e, value,"Warehouse", "warehouse_number")},
                                        v: "warehouse_number",
                                        value_name: "warehouse_name",
                                        select_options: warehouse_service,
                                        helperText: statusFormData.Warehouse.message,
                                        required: statusFormData.Warehouse.required,
                                        error: statusFormData.Warehouse.status,
                                        sx: {width: 130}
                                    })}
                                </Paper>

                                <Paper elevation={3} sx={{
                                    padding: 3,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start', // 修改为'flex-start'
                                    alignItems: 'flex-start', // 修改为'flex-start'
                                    mb: 1,
                                    border: '1px solid',
                                    borderColor: 'blue'
                                }}>
                                    <Typography sx={{
                                        fontSize: 14,
                                        marginTop: '-15px',
                                        marginLeft: '-15px'
                                    }} color="text.secondary" gutterBottom>
                                        Recipient address
                                    </Typography>
                                    <RenderTextField
                                        idAname="name"
                                        label="Name"
                                        value={formData.Name}
                                        onChangeHandler={handleFieldChange('Name')}
                                        width={12}
                                        required={statusFormData.Name.required}
                                        error={statusFormData.Name.status}
                                        helperText={statusFormData.Name.message}
                                    />
                                    <RenderTextField
                                        idAname="Address1"
                                        label="Address1"
                                        value={formData.Address1}
                                        onChangeHandler={handleFieldChange('Address1')}
                                        width={12}
                                        required={statusFormData.Address1.required}
                                        error={statusFormData.Address1.status}
                                        helperText={statusFormData.Address1.message}
                                    />
                                    <RenderTextField
                                        idAname="Address2"
                                        label="Address2"
                                        value={formData.Address2}
                                        onChangeHandler={handleFieldChange('Address2')}
                                        width={12}
                                    />
                                    <RenderTextField
                                        idAname="City"
                                        label="City"
                                        value={formData.City}
                                        onChangeHandler={handleFieldChange('City')}
                                        width={12}
                                        required={statusFormData.City.required}
                                        helperText={statusFormData.City.message}
                                        error={statusFormData.City.status}
                                    />
                                    {RenderSelectField({
                                        idAname: "State",
                                        label: "State",
                                        value: formData.State,
                                        onChangeHandler: (e, value) => {handleChange(e, value,"State", "abbreviation")},
                                        v: "abbreviation",
                                        value_name: "abbreviation",
                                        select_options: states,
                                        helperText: statusFormData.State.message,
                                        required: statusFormData.State.required,
                                        error: statusFormData.State.status,
                                        sx: {width: 125}
                                    })}

                                    <RenderTextField
                                        idAname="PostalCode"
                                        label="PostalCode"
                                        value={formData.PostalCode}
                                        onChangeHandler={handleFieldChange('PostalCode')}
                                        width={12}
                                        helperText={statusFormData.PostalCode.message}
                                        required={statusFormData.PostalCode.required}
                                        error={statusFormData.PostalCode.status}
                                    />
                                    <RenderTextField
                                        idAname="PhoneNumber"
                                        label="PhoneNumber"
                                        value={formData.PhoneNumber}
                                        onChangeHandler={handleFieldChange('PhoneNumber')}
                                        width={12}
                                        required={statusFormData.PhoneNumber.required}
                                        error={statusFormData.PhoneNumber.status}
                                        helperText={statusFormData.PhoneNumber.message}
                                    />
                                    <RenderTextField
                                        idAname="Reference"
                                        label="Reference"
                                        value={formData.Reference}
                                        onChangeHandler={handleFieldChange('Reference')}
                                        width={12}
                                        required={statusFormData.Reference.required}
                                        error={statusFormData.Reference.status}
                                        helperText={statusFormData.Reference.message}
                                    />
                                    <RenderTextField
                                        idAname="Email"
                                        label="Email"
                                        value={formData.Email}
                                        onChangeHandler={handleFieldChange('Email')}
                                        width={12}
                                        error={statusFormData.Email.status}
                                        helperText={statusFormData.Email.message}
                                    />
                                    <RenderTextField
                                        idAname="phone extension"
                                        label="phone extension"
                                        value={formData.PhoneExtension}
                                        onChangeHandler={handleFieldChange('PhoneExtension')}
                                        width={12}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={9} md={9} lg={9}>
                                <Paper elevation={3} sx={{
                                    width: '100%', maxWidth: '100%', // padding: 3, display: 'flex', justifyContent: 'flex-end',
                                    // alignItems: 'center', flexWrap: 'nowrap', overflowX: 'auto'
                                }}>
                                    <DimensionForm
                                        cards={cards} setCards={setCards} addCard={addCard}
                                        dimension_status={dimension_status} subPackageType={subPackagingType}
                                        deleteCard={deleteCard}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>取消</Button>
                    <Button onClick={onClear} color={"secondary"}>清空</Button>
                    <LoadingButton loading={loading} onClick={handleSaves} variant={"contained"}>保存</LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={() => setSnackbar({...snackbar, open: false})}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}
                       sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>

    );
};

export default CreateFormDialog;
