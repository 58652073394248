import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import {connect} from "react-redux";
import {updateClaim} from "../../redux/actions/modal";
import store from "../../redux/store";
import {MODAL_CLOSE_ALERT, UPDATE_CLAIM_OPEN} from "../../redux/type";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import server from "../../server";

const re = {regex: /^(\+|-)?\d+(\.\d{0,2})?$/};

class UpdateClaim extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            credit: 0,
            claim_id: null
        }
    }

    Close = () => {
        this.setState({open: false})
    }

    Open = () => {
        if (this.props.claim_update_list && this.props.claim_update_list.length === 1){
            for (let i = 0; i < this.props.claim_update_list.length; i++) {
                for (let x = 0; x < this.props.data.length; x++) {
                    if (this.props.data[x].id === this.props.claim_update_list[i]) {
                        if (this.props.data[x].status === '1'){
                            store.dispatch({type: UPDATE_CLAIM_OPEN, payload: {level: 'error', title: 'FAILED',message: '已确认索赔无法修改金额'}})
                            return
                        }
                        this.setState({credit: this.props.data[x].Check_amount, claim_id: this.props.data[x].id})
                        break
                    }
                }
            }
            this.setState({open: true})
        }else{

            store.dispatch({type: UPDATE_CLAIM_OPEN, payload: {level: 'error', title: 'FAILED',message: '请勾选一项进行修改'}})
            this.setState({open: false})
        }
    }
    handleSubmit = e => {
        e.preventDefault()
        this.props.updateClaim(this.state.credit, this.state.claim_id)
        this.setState({open: false})
    }
    closeAlert = () => {
        store.dispatch({type: MODAL_CLOSE_ALERT})
    }

    render() {
        return (
            <span>
                <Button sx={{textTransform: 'none'}} startIcon={<AttachMoneyIcon/>} onClick={() => {
                    this.Open()
                }}>修改金额</Button>
                    <Dialog disableEscapeKeyDown open={this.state.open} onClose={() => {
                        this.Close()
                    }}>
                        <DialogTitle>修改金额</DialogTitle>
                        <DialogContent>
                            <Box component="form" onSubmit={this.handleSubmit} sx={{display: 'flex', flexWrap: 'wrap'}}>
                                <FormControl sx={{m: 1, minWidth: 500}}>
                                        <TextField id="Credit" name="Credit" inputMode="decimal" type="number"
                                           value={this.state.credit}
                                           label="Credit" variant="standard"
                                           helperText="Please set the recharge amount,Maximum two decimal places"
                                           onChange={(value) => {
                                               if (re.regex.test(value.target.value) || value.target.value === '') {
                                                   this.setState({credit: value.target.value})
                                               }
                                           }}
                                           required
                                        />
                                    </FormControl>

                                <DialogActions>
                                    <Button fullWidth variant="contained" sx={{mt: 3, mb: 2, ml: 45}} onClick={() => {
                                        this.Close()
                                    }}>取消</Button>
                                    <Button fullWidth type='submit' variant="contained" sx={{mt: 3, mb: 2}}>确认</Button>
                                </DialogActions>
                            </Box>
                        </DialogContent>
                    </Dialog>
            </span>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    ModalLoading: state.ClaimReducer.ModalLoading,
    isLoading: state.ClaimReducer.isLoading,
    alert: state.ClaimReducer.alert,
    user: state.auth.user,
    data: state.ClaimReducer.data,
    claim_update_list: state.ClaimReducer.claim_update_list
});

export default connect(mapStateToProps, {updateClaim})(UpdateClaim);