import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Box,
    InputAdornment,
    Snackbar
} from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import server from "../../../server"
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

function UploadClaimA({ uploadClaim, Refresh }) {
    const [open, setOpen] = useState(false);
    const [uploadClaimFile, setUploadClaimFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (uploadClaimFile) {
                // 创建FormData对象，并将文件添加进去
                const formData = new FormData();
                formData.append('upload_claim_file', uploadClaimFile, uploadClaimFile.name);

                const result = await uploadClaim(formData);
                if (result.status === 200) {
                    setSnackbar({open: true, message: '上传成功！', severity: 'success'});
                    handleClose();
                }else {
                    setSnackbar({open: false, message: '上传失败！', severity: 'error'});
                    handleClose();
                }
            } else {
                setSnackbar({open: true, message: '请添加文件！', severity: 'warning'});
            }
        } catch (e) {
            console.error("上传失败", e);
        } finally {
            setLoading(false);
            Refresh()

        }
    };
    const handleChangeFile = (e) => {
        // 获取文件并设置，这里我们直接保存文件对象
        const file = e.target.files[0];
        if (file) {
            setUploadClaimFile(file);
        }
    };

    const downloadTemplate = () => {
        const a = document.createElement('a');
        a.target = "_blank";
        if (server.test) {
            a.href = `${server.test_host}:${server.post}/DownloadTemplate?file=claim_template.xlsx`;
        } else {
            a.href = `${server.host}?file=claim_template.xlsx`;
        }
        a.click();
    };

    return (
        <span>
            <Button sx={{ textTransform: 'none' }} startIcon={<FileUploadOutlinedIcon />} onClick={handleOpen}>
                上传索赔表
            </Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>上传索赔表</DialogTitle>
                <DialogContent>
                    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControl sx={{ m: 1, minWidth: 500, minHeight: 500 }}>
                            <TextField
                                id="upload_claim_file"
                                name="upload_claim_file"
                                type="file"
                                label="Please upload the claim information file (xlsx format)"
                                onChange={handleChangeFile}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FileUploadIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />
                        </FormControl>
                        <DialogActions>
                            <LoadingButton loading={loading} fullWidth variant="contained" sx={{ mt: 3, mb: 2, ml: 38, minWidth: 100 }} onClick={downloadTemplate}>
                                下载模板
                            </LoadingButton>
                            <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleClose}>
                                取消
                            </Button>
                            <LoadingButton loading={loading} fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                                上传
                            </LoadingButton>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </span>
    );
}

export default UploadClaimA;