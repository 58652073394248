import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button} from '@mui/material';
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import {useDispatch} from "react-redux";
import {RenderSelectField} from "../../../../components/renderField";

const EditFormDialog = ({ open, onClose, rowData, onSave }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        warehouse_number: '',
        name: 'EWR_CENTER',
        IATA: 'EWR',
        address1: '1 Broadway road suite 1',
        address2: '',
        city: 'Cranbury',
        postal_code: '08512',
        state: 'NJ',
        phone: '0000000000',
        company_name: 'Shipping dept',
        country: 'US',
        nameToChinese: "美东",
        user: '',
        is_specific: false,
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const [user_info, setUserInfo] = useState({});
    const [user_options, setUserOptions] = useState([])
    const dispatch = useDispatch();
    const [supplier_info, setSupplierInfo] = useState({})
    // 当rowData改变时，更新表单数据
    useEffect(() => {
        if (rowData) {
            let row_data = {}
            Object.entries(rowData).map(([key, value]) => {
                if (key === "user"){
                }

                    row_data[key] = value

            })
            setFormData(row_data);
        }
    }, [rowData]);

    const handleChange = (event) => {
        const { name, value } = event.target;

            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));

    };
    const handleFieldChange = (event, value, key, value_key) => {
            setFormData((prev) => ({
                ...prev,
                [key]: value?value[value_key]:'',
            }));

    };
    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.user_url}?page=0&size=100`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].username})
                        }
                        setUserOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        const fetchUserData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: server.user_list_url,
                    method: 'GET',
                    success: (res) => {
                        let UserList = res.data.UserList
                        for (let [key, value] of Object.entries(UserList)) {
                            setUserInfo((prev) => ({
                                ...prev,
                                [key]: value
                            }));
                        }
                    }
                })
            } catch (error) {
                console.error('请求异常用户列表异常', error);
            }
        }
        fetchData();
        fetchUserData()
    }, [dispatch]);
    const handleSave = async () => {
        setLoading(true);
        try {
            const result = await onSave(formData, formData.id, 'PUT');
            if (result.status === true) {
                // 操作成功，显示成功消息
                // setSnackbar({ open: true, message: '保存成功！', severity: 'success' });
            }
        } catch (error) {
            console.error("保存失败", error);
            // 操作失败，显示错误消息
            // setSnackbar({ open: true, message: '保存失败，请重试。', severity: 'error' });
        } finally {
            // setLoading(false);
            onClose(); // 保存操作完成后关闭对话框
        }
    };
    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>编辑信息</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="warehouse_number"
                                label="仓库编号"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.warehouse_number}
                                onChange={handleChange}
                                required
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>

                            <RenderSelectField
                                margin="dense"
                                idAname="user"
                                label="用户"
                                value={formData.user}
                                select_options={user_options}
                                v="id"
                                value_name="name"
                                onChangeHandler={(e, value) => handleFieldChange(e, value, "user", "id")}
                                sx={{width: 353}}
                            />
                            {/*    <MenuItem aria-label="None" key={'Warehouse_None'} value={""}>不选择</MenuItem>*/}

                            {/*    {user_options.map((option) => (*/}
                            {/*        <MenuItem key={option.id} value={option.id}>*/}
                            {/*            {option.name}*/}
                            {/*        </MenuItem>*/}
                            {/*    ))}*/}
                            {/*</RenderSelectField>*/}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="name"
                                label="仓库名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.name}
                                onChange={handleChange}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="IATA"
                                label="IATA"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.IATA}
                                onChange={handleChange}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="address1"
                                label="地址一"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.address1}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="address2"
                                label="地址二"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.address2}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="city"
                                label="城市"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="postal_code"
                                label="邮编"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.postal_code}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="state"
                                label="州/省简称"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.state}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="phone"
                                label="电话"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="company_name"
                                label="公司名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.company_name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="country"
                                label="国家/地区"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.country}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="nameToChinese"
                                label="nameToChinese"
                                type="nameToChinese"
                                fullWidth
                                variant="standard"
                                value={formData.nameToChinese}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="is_specific"
                                label="是否填入发运仓"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.is_specific}
                                onChange={handleChange}
                            >
                                <MenuItem key="is_specific_false" value={false}>
                                    否
                                </MenuItem>
                                <MenuItem key="is_specific_true" value={true}>
                                    是
                                </MenuItem>
                            </TextField>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>取消</Button>
                    <Button loading={loading} onClick={handleSave} variant={"contained"}>保存</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditFormDialog;
