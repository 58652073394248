import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Snackbar} from '@mui/material';
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import {useDispatch} from "react-redux";
import {RenderSelectField} from "../../../../components/renderField";

const EditFormDialog = ({ open, onClose, rowData, onSave }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        user: '',
        supplier: '',
        region: 'EWR',
        name: '',
        account_user: '---',
        account_kwargs_1: '---',
        account_kwargs_2: '---',
        account_kwargs_3: '---',
        account_kwargs_4: '---',
        account_kwargs_5: '---',
        account_kwargs_6: '---',
        account_kwargs_7: '---',
        isDebug: false,
        isDefault: false,
        isSystemDefault: false,
        optional: '{}',
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const [user_info, setUserInfo] = useState([]);
    const [supplier_options, setSupplierOptions] = useState([]);
    const dispatch = useDispatch();
    const [supplier_info, setSupplierInfo] = useState({})
    // 当rowData改变时，更新表单数据
    useEffect(() => {
        if (rowData) {
            let row_data = {}
            Object.entries(rowData).map(([key, value]) => {

                if(key === "supplier" || key === "user"){
                    row_data[key] = value["id"]
                }

                else {
                    row_data[key] = value
                }
            })
            setFormData(row_data);
        }
    }, [rowData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        console.log('supplier',formData.supplier)

        if (name==="user"){
            let Name
            if (value === "1"){
                Name = "System"
            }
            else {
                console.log(user_info)
                Name = user_info[value].split("(")[0]
            }
            setFormData((prev) => ({
                ...prev,
                [name]: value,
                name: Name
            }));
        }

        else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.logistics_supplier_list}?page=0&size=100`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                            setSupplierInfo((prev) => ({
                                ...prev,
                                [JSON.stringify(rows[i].id)]: rows[i].name
                            }));
                        }
                        setSupplierOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        const fetchUserData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: server.user_list_url,
                    method: 'GET',
                    success: (res) => {
                        let UserList = res.data.UserList
                        let opt = []
                        for (let [key, value] of Object.entries(UserList)) {
                            opt.push({id: key, name: value})
                        }
                        setUserInfo(opt)
                    }
                })
            } catch (error) {
                console.error('请求异常用户列表异常', error);
            }
        }
        fetchData();
        fetchUserData()
    }, [dispatch]);
    const handleSave = async () => {
        setLoading(true);
        try {
            const result = await onSave(formData, formData.id, 'PUT');
            if (result.status === true) {
                // 操作成功，显示成功消息
                // setSnackbar({ open: true, message: '保存成功！', severity: 'success' });
            }
        } catch (error) {
            console.error("保存失败", error);
            // 操作失败，显示错误消息
            // setSnackbar({ open: true, message: '保存失败，请重试。', severity: 'error' });
        } finally {
            // setLoading(false);
            onClose(); // 保存操作完成后关闭对话框
        }
    };
    const handleFieldChange = (event, value, key, value_key) => {
        setFormData((prev) => ({
            ...prev,
            [key]: value?value[value_key]:'',
        }));

    };
    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>编辑信息</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>

                            <RenderSelectField
                                idAname="user"
                                label="可用用户"
                                value={formData.user}
                                select_options={user_info}
                                v="id"
                                value_name="name"
                                onChangeHandler={(e, value) => handleFieldChange(e, value, "user", "id")}
                                sx={{width: 350}}
                                required
                            />

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                idAname="supplier"
                                label="渠道商"
                                value={formData.supplier}
                                select_options={supplier_options}
                                v="id"
                                value_name="name"
                                onChangeHandler={(e, value) => handleFieldChange(e, value, "supplier", "id")}
                                sx={{width: 350}}
                                required
                            />

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="region"
                                label="口岸"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.region}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="name"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_user"
                                label="账号名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_user}
                                defaultValue={formData.account_user}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_kwargs_1"
                                label="参数1"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_1}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_kwargs_2"
                                label="参数2"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_2}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_kwargs_3"
                                label="参数3"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_3}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_kwargs_4"
                                label="参数4"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_4}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_kwargs_5"
                                label="参数5"
                                type="text"
                                fullWidth
                                variant="standard"
                                defaultValue='---'
                                value={formData.account_kwargs_5}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_kwargs_6"
                                label="参数6"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_6}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_kwargs_7"
                                label="参数7"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_7}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="isDefault"
                                label="是否默认账号"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.isDefault}
                                defaultValue={formData.isDefault}
                                onChange={handleChange}
                            >
                                <MenuItem key="isDefault_1" value={true}>
                                    是
                                </MenuItem>
                                <MenuItem key="isDefault_0" value={false}>
                                    否
                                </MenuItem>

                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="isDebug"
                                label="是否测试账号"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.isDebug}
                                onChange={handleChange}
                            >
                                <MenuItem key="isDebug_1" value={true}>
                                    是
                                </MenuItem>
                                <MenuItem key="isDebug_0" value={false}>
                                    否
                                </MenuItem>

                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="isSystemDefault"
                                label="是否系统默认账号"
                                type="text"
                                fullWidth
                                variant="standard"
                                select
                                value={formData.isSystemDefault}
                                onChange={handleChange}
                            >
                                <MenuItem key="isSystemDefault_1" value={true}>
                                    是
                                </MenuItem>
                                <MenuItem key="isSystemDefault_0" value={false}>
                                    否
                                </MenuItem>

                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="optional"
                                label="其他参数"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.optional}
                                onChange={handleChange}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>取消</Button>
                    <Button loading={loading} onClick={handleSave} variant={"contained"}>保存</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default EditFormDialog;
