import React, { useState, useEffect } from 'react';
import {Button} from '@mui/material';
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {DataGridPro} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../../../components/CustomNoRowsOverlay";
import UploadZone from "../../../modal/UploadZone";
import {AxiosDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function formatCell(params) {
    const num = parseFloat(params.value);
    if (isNaN(num)){
        return ''
    }

    return num
    // if (!["FEDEX_MWT", "UPS_HWT"].includes(params['row']["service_name"])) {
    //     return num.toFixed(2);
    // }
    // return num;
}
function Download({download, service_name, year, quarter}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <div style={{right : 10, display: 'inline-block', padding: 1}}>
            <Button
                variant="contained"
                onClick={handleClick}
            >
                导出
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'download-template-button',
                }}
            >
                <MenuItem onClick={() => download(service_name, year ,quarter, 'xlsx')}>导出excel(.xlsx)</MenuItem>
                <MenuItem onClick={() => download(service_name, year ,quarter, 'json')}>导出json</MenuItem>
            </Menu>
        </div>
    );
}

const UpdaterZonePage= ({ open, onClose, setSnackbar,
                            SetApiLoading, service_name, year, quarter, rowData, download
                        }) => {

    const [formData, setFormData] = useState([]);
    const [Visibility, setVisibility] = useState({
        id: false
    });
    const [loading, setLoading] = useState(false)
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [row_s, setData] = useState([])
    const CellEditZone = (params) =>{
        setLoading(true)
        let rows = JSON.parse(JSON.stringify(params.row));
        // for(let i in formData){
        //     if(formData[i]["id"] === params.row.id){
        //         rows["zone"] = params.value
        //         let data = JSON.parse(JSON.stringify(formData))
        //         data[i] = rows
        //         setFormData(data)
        //     }
        // }
        let row = JSON.parse(JSON.stringify(params.row));
        let new_value = params.value
        row["zone"] = new_value
        let field = `${params.field}`
        let id = field.split("_")[1]
        row["id"] = id
        AxiosDefaultRequest({
            url: server.zone_list,
            method: 'PUT',
            data: row,
            success: res => {
                let result = res.data;
                if (result.status) {
                    // result['request'] = true
                    // result['level'] = 'success'
                    // result['title'] = 'SUCCESS'
                    // result['message'] = result['message']
                    for(let i in formData){
                        if(formData[i]["id"] === params.row.id){
                            rows[params.field] = params.value
                            let data = JSON.parse(JSON.stringify(formData))
                            data[i] = rows
                            setFormData(data)
                        }
                    }
                    setSnackbar({open: true, message: "修改成功！", severity: 'success'})
                    // dispatch({type: UPLOAD_INVOICE_SUCCESS, payload: result})
                    setLoading(false)
                } else {
                    // result['request'] = false
                    // result['level'] = 'error'
                    // result['title'] = 'FAILED'
                    // result['message'] = result['message']
                    setSnackbar({open: true, message: result['message'], severity: 'error'})
                    setLoading(false)
                    // dispatch({type: UPLOAD_INVOICE_ERR, payload: result})
                }


            }, err: res => {
                setLoading(false)
                // dispatch({type: UPLOAD_INVOICE_ERR, payload: result})
            }
        })


    }
    const columns = [
        // {id: }
        {id: 1, field: "id", headerName: "ID", flex: 5},
        {id: 2, field: "postal", headerName: "邮编", flex: 5},
    ]
    for(let index in rowData){
        // zone_jsons.push(rowData[index].zone_json)
        let field = `${rowData[index]["region"]}-${rowData[index]["shipper_zip"]?rowData[index]["shipper_zip"]:''}`
        columns.push({id: 3 + index, field: `${field}_${rowData[index]["id"]}`, headerName: field, width: 100, editable: true})

    }

    const handleSortModelChange = async (sortModel) => {
        if (sortModel.length === 0){
            return
        }
        let sort_data = sortModel[0]

        let field = sort_data.field
        let sort = sort_data.sort
        let data = JSON.parse(JSON.stringify(formData));
        if (sort === "asc"){
            data.sort((a, b) => {
                return a[field] - b[field]; // 升序排序
            });
        }
        else {
            data.sort((a, b) => {
                return b[field] - a[field]; // 升序排序
            });
        }
        setFormData(data)
        const startIndex = currentPage * pageSize;
        const endIndex = startIndex + pageSize;

        // 切割数组
        formData.slice(startIndex, endIndex);
        // setData(rows)
    };
    // const ChangeColumnVisibilityModel(newModel)
    useEffect(() => {
        let row_data = []
        let ID = 1


        if (rowData){

            // 收集所有键
            let zone_jsons = []
            for(let index in rowData){
                zone_jsons.push(rowData[index].zone_json)

            }
            const allKeys = zone_jsons.flatMap(Object.keys);

// 找到最大键
            const maxKey = allKeys.reduce((max, key) => (key > max ? key : max), '');
            const new_maxKey = maxKey.replace(/0/g, '');
            let len = rowData.length
            let data = []
            for(let index = 0; index <= parseInt(new_maxKey); index++){
                let postal = `${index}`.padStart(3, '0')
                let line = {postal, id: postal}
                let is_push = false
                for(let i = 0; i < len;i++){
                    if(rowData[i]["zone_json"].hasOwnProperty(postal) && rowData[i]["zone_json"][postal] !== ''
                    && rowData[i]["zone_json"][postal] !== null
                    ){
                        is_push = true
                        // line["idd"] = rowData[i]["id"]
                        line[`${rowData[i]["region"]}-${rowData[i]["shipper_zip"]?rowData[i]["shipper_zip"]:''}_${rowData[i]["id"]}`] = rowData[i]["zone_json"][postal]
                    }

                    // line["id"] = index
                }
                if(is_push){
                    data.push(line)
                }

            }
            setFormData(data)
            // setPageSize(Size)
            // 计算起始和结束索引
            const startIndex = currentPage * pageSize;
            const endIndex = startIndex + pageSize;

            // 切割数组
            formData.slice(startIndex, endIndex);
            // setData(row)

        }

    }, [currentPage, pageSize, rowData]);
    const ChangeCurrentPage =(page) => {
        setCurrentPage(page)
        // 计算起始和结束索引
        const startIndex = page * pageSize;
        const endIndex = startIndex + pageSize;

        // 切割数组
        // let data = JSON.parse(JSON.stringify(formData))
        formData.slice(startIndex, endIndex);
        // setData(row)
    }
    const ChangeCurrentPageSize =(Size) => {
        setPageSize(Size)
        // 计算起始和结束索引
        const startIndex = currentPage * pageSize;
        const endIndex = startIndex + Size;

        // 切割数组
        formData.slice(startIndex, endIndex);
        // setData(rows)
    }

    return (
        <>
            {/*<Button startIcon={<ListAltIcon/>} onClick={onClose}>附加费*/}
            {/*</Button>*/}

            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="shipping-fee-title"
                aria-describedby="shipping-fee-description"
            >

                <Paper
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        maxHeight: '90vh',
                        padding: 2,
                        overflow: 'auto' // 这里添加滚动
                    }}
                >

                    <Box sx={{display: 'inline-block', float: "right", padding: 2}}>
                        {/*<Typography variant="h6">{this.state.PFN}-{this.state.logisticService}</Typography>*/}

                        <UploadZone setSnackbar={setSnackbar} SetApiLoading={SetApiLoading}
                                    service_name={service_name} year={year} quarter={quarter}></UploadZone>
                        <Download download={download} service_name={service_name} year={year} quarter={quarter}/>
                        <IconButton
                            sx={{position: 'relative', float: "right", width: 10, display: 'inline-block'}}
                            onClick={onClose}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Box>

                    {/* content */}
                    <Grid container item md={12} xs={12} lg={12} spacing={1}>
                        <DataGridPro
                            rows={formData}
                            rowHeight={50}
                            columns={columns}
                            sx={{
                                // height: 400,
                                // width: '100%',
                                display: 'block',
                                // display: 'flex',
                                // p: 2,
                                // flexDirection: 'column',
                                minHeight: '60vh', // 最小高度
                                maxHeight: '60vh',
                                // 最大高度，根据需要调整
                                overflow: 'auto', // 开启内部滚动
                                '& .MuiDataGrid-cell': {
                                    border: '1px solid rgba(0, 0, 0, 0.1)'
                                },
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: '#92d050', // 设置您喜欢的颜色
                                    // position: 'sticky',
                                    // top: 0,
                                    // zIndex: 1,
                                    // display: 'table-header-group', // 使用合适的显示属性
                                }
                            }}

                            onPageChange={(page) => ChangeCurrentPage(page)}
                            onPageSizeChange={pageSize => ChangeCurrentPageSize(pageSize)}
                            rowsPerPageOptions={[20, 30, 50, 100, 150, 200, 500]}
                            hideFooter={false}
                            autoHeight={true}
                            loading={loading}
                            rowCount={formData.length}
                            paginationMode='client'
                            columnVisibilityModel={Visibility}
                            // onColumnVisibilityModelChange={(newModel) =>
                            //     ChangeColumnVisibilityModel(newModel)
                            // }
                            pageSize={pageSize}
                            page={currentPage}
                            rowReordering={false}
                            onColumnOrderChange={(e) => {
                                this.ColumnOrderChange(e)
                            }}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                // Toolbar: APIToolBar,
                                // Toolbar: (props) => <APIToolBar {...props} options={<UploadZone setSnackbar={setSnackbar} SetApiLoading={SetApiLoading}/>}/>
                            }}
                            // onPageChange={(page) => this.useQuery(page)}
                            // onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                            onSortModelChange={handleSortModelChange}
                            onCellEditCommit={params => {
                                CellEditZone(params)
                            }}
                            editMode={'cell'}
                            pagination={true}
                        />
                    </Grid>
                    {/*<Button variant="contained" sx={{width: 100, mt: 0.5, mb: 0.5, float: "right"}} onClick={() => this.setState({...this.state, add_page_open: true})}>新增</Button>*/}
                    {/*<EditFormDialog open={this.state.add_page_open} setOpen={this.AddOpen} onSave={this.handleAddOfferSheet}></EditFormDialog>*/}
                </Paper>
            </Modal>
        </>

    );
}


export default UpdaterZonePage