import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {CustomerClaimBar} from "./claimSearchBar/CustomerClaimBar";
import StyledBox from "../../components/StyledBox";
import {
    DataGridPro,
} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";
import server from "../../server";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import ToolBar from "../../components/ToolBar";
import Alert from "@mui/material/Alert";
import {Chip, DialogContentText, Snackbar} from "@mui/material";
import CreateCustomerClaim from "./Dialog/CreateCustomerClaim";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import MultiFileUpload from "../../components/MultiFileUpload";
import UploadCustomerClaim from "./Dialog/UploadCustomerClaim";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RemoveCustomerClaim from "./Dialog/RemoveCustmoerClaim";
import FormControl from "@mui/material/FormControl";
import UpdateCustomerClaim from "./Dialog/UpdateCustomerClaim";
import GetAppIcon from '@mui/icons-material/GetApp';
import axios from "axios";

const CustomerClaims = "CustomerClaims";
function getUtcOffsetDetailed() {
    const offsetMinutes = new Date().getTimezoneOffset();
    const hours = Math.floor(Math.abs(offsetMinutes) / 60);
    const minutes = Math.abs(offsetMinutes) % 60;

    const sign = offsetMinutes > 0 ? '-' : '+';

    return `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}
function verify_format(params) {
    if (params.value === "2000-01-01 00:00:00"){
        return ``
    }else{
        const utcDate = new Date(params.value + "Z")
        return `${utcDate.toLocaleString()} ${getUtcOffsetDetailed()}`
    }
}
function create_format(params) {
    const utcDate = new Date(params.value + "Z")
    return `${utcDate.toLocaleString()} ${getUtcOffsetDetailed()}`

}

function CustomSelectCell({ id, options, value, field, onChange, claim_status }) {
    // 判断当前选项是否为"Paid"，如果是，则禁用下拉框
    let isDisabled;
    if (field === "returned" && claim_status === "Paid") {
        isDisabled = false;
    }else {
        isDisabled = value === "Paid"
             || claim_status === "Paid" || claim_status === "Unqualified";
    }
    // const isDisabled = false;
    return (
        <FormControl fullWidth>
            <Select
                value={value}
                onChange={onChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                size="small"
                disabled={isDisabled} // 根据条件禁用Select组件
                sx={{
                    '.MuiSelect-select': {
                        paddingTop: '6px',
                        paddingBottom: '7px',
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    '&:hover': {
                        '.MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

function CustomerClaimsPage() {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [selectedRow, setSelectedRow] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(false);
    const [openUploadAttachmentDialog, setOpenUploadAttachmentDialog] = useState(false);
    const [uploadAttachmentLoading, setUploadAttachmentLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const user = useSelector((state) => state.auth.user);
    const [downloadInfoLoading, setDownloadInfoLoading] = useState(false);
    const [searchData, setSearchData] = useState({
        Case_number: "", Tracking_number: "", EFT: "", UID: "",
        start_check_time: null, start_upload_time: null, supplier: "",
        end_check_time: null, end_upload_time: null,
        status: [], returned: [], claim_type: [],
    });
    const statusOptions = [
        "Open", "Submitted", "Unqualified", "Denied",
        "Paid", "Closed Without Payment", "Resubmitted", "出账中"];
    const returnedOptions = ["处理中", "未退款", "已退款"];
    const ClaimTypeOptions = ["Misdelivery", "Shipment Not Received",
        "Shipment Missing Contents", "Shipment Damaged", "COD Payment Not Collected"];
    const [Visibility, setVisibility] = useState({
        id: false,
        Check_amount: parseInt(user.is_open_claim) === 4
    })
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    const updateRowData = (rowId, fieldName, newValue) => {
        const updatedRows = data.map(row => {
            if (row.id === rowId) {
                return { ...row, [fieldName]: newValue };
            }
            return row;
        });
        setData(updatedRows);
    };

    const handleEditCellChange = (params, updateField, event) => {
        let newValue;
        if (typeof event === "object" && event !== null){
            newValue = event.target.value;
            updateRowData(params.id, updateField, {name: newValue});
        }else {
            newValue = event;
            updateRowData(params.id, updateField, newValue);
        }
        fetchData('PUT', { "status": "cell", "update_field": updateField, 'value': newValue }, params.id)
            .then((response) => {
                if (response?.status === 204){
                    setSnackbar({open: true, message: `${updateField}修改成功!`, severity: 'success'});
                }else{
                    setSnackbar({open: true, message: `${updateField}修改失败!`, severity: 'error'});
                    Refresh();
                }
            }).catch((error) => {
                if (error.response?.status === 403){
                    console.error(`缺失权限,修改失败!`, error);
                    const error_message = error.response.data.error;
                    setSnackbar({open: true, message: `${updateField}修改失败,仅管理员可以修改状态`, severity: 'info'});
                    Refresh();
                }else{
                    console.error(`${updateField}修改失败!`, error);
                    const error_message = error.response.data.error;
                    setSnackbar({open: true, message: `${updateField}修改失败,${error_message}`, severity: 'error'});
                    Refresh();
                }
        });
    };

    // 假设你有一个函数来处理文件下载
    const handleDownload = (filename) => {
        let url;
        if (server.test) {
            url = server.test_host + ":" + server.post + server.download_get_ship_url + '?file=' + filename
        }else{
            url = server.host + server.download_get_ship_url + '?file=' + filename
        }
        window.open(url, '_blank');
    };
    const handleOpenUploadAttachmentDialog = (row) => {
        setSelectedRow(row);
        setOpenUploadAttachmentDialog(true);
    };
    const [columnWidths, setColumnWidths] = React.useState({

    });

    const handleColumnResize = (params) => {
        setColumnWidths((prevWidths) => ({
            ...prevWidths,
            [params.colDef.field]: params.width,
        }));
    };
    const columns = [
        {id: 1, field: "id", headerName: "ID"},
        {id: 2, field: "UID", headerName: "UID", width: columnWidths['UID'] || 100},
        {
            id: 3,
            field: "status",
            headerName: "索赔状态",
            width: columnWidths['status'] || 150,
            renderCell: (params) => {
                const canChangeClaim = user.is_superuser || user.permissions.includes("Can change claim");
                return canChangeClaim ? (
                    <CustomSelectCell
                        key={`status-${params.id}`}
                        id={params.id}
                        value={params.value ? params.value.name : ""}
                        field={params.field}
                        options={statusOptions}
                        onChange={(event) => handleEditCellChange(params, "status", event)}
                    />
                ) : (
                    <span>{params.value ? params.value.name : ""}</span>
                );
            }
        },
        {id: 4, field: "supplier", headerName: "渠道", width: columnWidths['supplier'] || 120},
        {id: 5, field: "create_time", headerName: "上传时间", width: columnWidths['create_time'] || 150, valueFormatter: create_format},
        {id: 6, field: "verify_time", headerName: "确认时间", width: columnWidths['verify_time'] || 150, valueFormatter: verify_format},
        {id: 7, field: "Case_number", headerName: "Case号码", width: columnWidths['Case_number'] || 120},
        {id: 8, field: "Tracking_number", headerName: "追踪号", width: columnWidths['Tracking_number'] || 200},
        {id: 9, field: "claim_type", headerName: "索赔类型", width: columnWidths['claim_type'] || 150,
            renderCell: (params) => {
                const canChangeClaim = user.is_superuser || user.permissions.includes("Can change claim");
                return canChangeClaim ? (
                    <CustomSelectCell
                        key={`claim_type-${params.id}`}
                        id={params.id}
                        value={params.value ? params.value.name : ""}
                        field={params.field}
                        options={ClaimTypeOptions}
                        claim_status={params.row.status.name}
                        onChange={(event => handleEditCellChange(params, "claim_type", event))}
                    />
                ) : (
                    <span>{params.value ? params.value.name : ""}</span>
                );
            }
        },
        {id: 10, field: "EFT", headerName: "EFT", width: columnWidths['EFT'] || 120},
        {id: 11, field: "invoice_number", headerName: "账单号", width: columnWidths['invoice_number'] || 100},
        {id: 12, field: "Claim_amount", headerName: "索赔金额", width: columnWidths['Claim_amount'] || 120},
        {id: 13, field: "Check_amount", headerName: "确认金额", width: columnWidths['Check_amount'] || 100},
        {
            id: 14, field: "returned", headerName: "退款状态", resizable: false, width: columnWidths['returned'] || 100,
            renderCell: (params) => {
                const canChangeClaim = user.is_superuser || user.permissions.includes("Can change claim");
                return canChangeClaim ? (
                    <CustomSelectCell
                        key={`returned-${params.id}`}
                        id={params.id}
                        value={params.value ? params.value.name : ""}
                        field={params.field}
                        options={returnedOptions}
                        claim_status={params.row.status.name}
                        onChange={(event => handleEditCellChange(params, "returned", event))}
                    />
                ) : (
                    <span>{params.value ? params.value.name : ""}</span>
                );
            }
        },
        {id: 15, field: "note", headerName: "备注", width: columnWidths['note'] || 150, editable: true},
        {id: 16, type: 'actions', field: 'actions', headerName: "Actions", width:100, getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<DownloadIcon />}
                        label="下载附件"
                        title={"下载附件"}
                        onClick={() => handleDownload(params.row.file_dir)}
                        key={`download-${params.id}`}
                        color="primary"
                        disabled={params.row.file_count === 0}
                    />,
                    <GridActionsCellItem
                        icon={<FileUploadIcon />}
                        label="上传附件"
                        title={"上传附件"}
                        onClick={() => handleOpenUploadAttachmentDialog(params.row)}
                        key={`upload-${params.id}`}
                        color="secondary"
                    />
                ];
            }}
        // next 16
    ];
    const uploadClaim = async (formData) => {
        try {
            const response = await AxiosSyncDefaultRequest({
                url: server.customer_claims_list,
                method: 'POST',
                data: formData, // 使用FormData作为请求体
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                timeout: 150000,
                success: (res) => {
                    // 处理成功的响应
                    console.log(res)  // TODO
                },
                fail: (error) => {
                    // 处理失败的响应
                    console.error('请求失败', error);
                    setData([]);
                    setLoading(false);
                    const errorMessage = JSON.stringify(error.response?.data) || error.message;
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // 这里可能不需要throw error，因为你已经在catch块中处理了异常
                }
            });
            return response; // 返回响应数据
        } catch (error) {
            console.error('请求异常', error);
            // 在这里处理异常
        }
    };
    const fetchData = async (method, data = {}, rowId = null, headers = {}) => {
        try {
            const queryParams = {
                ...searchData, // 将搜索条件作为查询参数
                curr_page: currentPage < 1 ? 1 : currentPage,
                page_size: pageSize,
            };

            let requestUrl = server.customer_claims_list;
            let requestData = {};

            if (method === 'GET') {
                requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);
            } else if (method === 'PUT' && rowId != null) {
                // 对于PUT请求，添加row id到URL，并设置编辑的数据作为请求体
                requestUrl = `${requestUrl}${rowId}/`;
                requestData = data;
            } else if (method === "DELETE") {
                requestUrl = `${requestUrl}${rowId}/`;
            } else if (method === "POST") {
                requestData = data;
            } else if (method === "PATCH") {
                requestUrl = `${requestUrl}batch_update/`;
                requestData = data;
            }

            // 直接返回AxiosSyncDefaultRequest调用
            return AxiosSyncDefaultRequest({
                url: requestUrl,
                method: method,
                data: requestData,
                headers: headers,
                success: (res) => {
                    if (method === 'GET') {
                        setData(res.data.rows);
                        setLoading(false);
                        setTotalRows(res.data.total);
                    } else {
                        return res;
                    }
                },
                fail: (error) => {
                    console.error('请求失败', error);
                    setData([]);
                    setLoading(false);
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    throw error;
                }
            });

        } catch (error) {
            console.error('请求异常', error);
            throw error;
        }
    }

    useEffect(() => {
        setLoading(true);
        // 调用上面定义的函数来加载数据
        fetchData('GET').then(r => {});
    }, [currentPage, pageSize]);
    const triggerSearch = () => {
        setData([]);
        setLoading(true)
        setSearch(true);
        fetchData('GET').then(r => {})
        setSearch(false);
    };
    const Refresh = () => {
        setData([]);
        setLoading(true);
        fetchData('GET').then(r => {})
        setSearch(false);
    };
    const handleUploadAttachment = (rowId) => {
        setUploadAttachmentLoading(true);
        const data = new FormData();
        // 添加文件到 FormData 对象，如果有多个文件，需要为每个文件调用 append

        // 计算所有文件的总大小
        let totalSize = 0;
        for (let i = 0; i < files.length; i++) {
            totalSize += files[i].size;
        }

        // 如果总大小超过10M，显示错误消息并停止上传
        if (totalSize > 10 * 1024 * 1024) {
            setSnackbar({open: true, message: '总文件大小超过10M!', severity: 'error'});
            setUploadAttachmentLoading(false);
            return;
        }

        for (let i = 0; i < files.length; i++) {
            data.append('upload_claim_file', files[i]);
        }
        data.append("status", "upload_attachment");
        return fetchData('PUT', data, rowId, {
            "Content-Type": "multipart/form-data"
            }
        ).then((e) => {
            Refresh();
            if (e?.status === 200) {
                setSnackbar({open: true, message: '附件上传成功!', severity: 'success'});
            }
        }).finally(() => {
            setUploadAttachmentLoading(false);
            setOpenUploadAttachmentDialog(false);
            setFiles([]);
        });
    }
    const onChangeColumnVisibilityModel = (newVisibility) => {
        if (user.is_open_claim !== "4") {
            newVisibility = { ...newVisibility, Check_amount: false };
        }
        setVisibility(newVisibility);
    }
    const downloadHandler = async () => {
        if (selectedRow.length === 0){
            setSnackbar({open: true, message: '请勾选任意一条信息进行下载!', severity: 'info'});
            return;
        }
        setDownloadInfoLoading(true);
        try {
            setDownloadInfoLoading(true);
            // 假设这是从后端获取到的文件名
            const response = await fetchData('PUT', {ids: selectedRow, status: 'downloadInfo'}, selectedRow[0]);
            if (response && response.data.file) {
                handleDownload(response.data.file);  // 使用你现有的函数来处理下载
            } else {
                throw new Error('未获得文件名，无法下载');
            }
        } catch (error) {
            console.error('下载文件失败:', error);
        } finally {
            setDownloadInfoLoading(false);
        }
    }
    return (
        <Box sx={{
            width: '100%'
        }}>
            <Grid container>
                <Grid container item md={12} lg={12}>
                    <Box sx={{minWidth: '100%'}}>
                        <CustomerClaimBar  // 搜索栏
                            searchData={searchData}
                            setSearchData={setSearchData}
                            search={search}
                            setSearch={triggerSearch}
                            setCurrentPage={setCurrentPage}
                        />
                        <CreateCustomerClaim  // 新建索赔
                            uploadClaim={uploadClaim}
                            Refresh={Refresh}
                            user={user}
                        />
                        <UploadCustomerClaim  // 批量上传索赔
                            uploadClaim={uploadClaim}
                            Refresh={Refresh}
                        />
                        {user.is_superuser || user.permissions.includes("Can change claim") ?
                            <UpdateCustomerClaim  // 修改索赔信息
                            data={data}
                            claim_update_list={selectedRow}
                            updateClaim={fetchData}
                            Refresh={Refresh}
                        /> : ""}
                        {user.is_superuser || user.permissions.includes("Can change claim") ?
                        <RemoveCustomerClaim
                            removeClaim={fetchData}
                            Refresh={Refresh}
                            selectRows={data.filter(row => Array.isArray(selectedRow) ? selectedRow.includes(row.id) : [])}
                        /> : ""}
                        <LoadingButton
                            sx={{textTransform: 'none'}} startIcon={<GetAppIcon/>}
                            loading={downloadInfoLoading}
                            onClick={downloadHandler}>下载索赔信息</LoadingButton>
                    </Box>
                </Grid>
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        {data ? <DataGridPro
                            editMode={"cell"}
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            checkboxSelection
                            headerHeight={70}
                            loading={loading}
                            rowCount={totalRows}
                            paginationMode='server'
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: () => <ToolBar onRefresh={Refresh} />,
                            }}
                            onSelectionModelChange={(e) => {
                                setSelectedRow(e)
                            }}
                            onCellEditCommit={(event) => {
                                handleEditCellChange(event, event.field, event.value)}
                            }
                            onColumnResize={handleColumnResize}
                            initialState={
                                {pinnedColumns: {right: ['actions']}}
                            }
                            columnBuffer={3}
                            columnThreshold={3}/> : ''}
                    </StyledBox>
                </Grid>
            </Grid>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Dialog open={openUploadAttachmentDialog} onClose={() => setOpenUploadAttachmentDialog(false)}>
                <DialogTitle>上传附件</DialogTitle>
                <DialogContent>
                    <MultiFileUpload
                        files={files}
                        setFiles={setFiles}
                        accept={"image/png, image/jpeg, application/pdf"}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUploadAttachmentDialog(false)}>否</Button>
                    <LoadingButton
                        loading={uploadAttachmentLoading}
                        variant={"contained"}
                        onClick={() => {
                            handleUploadAttachment(selectedRow.id)
                        }}
                    >是</LoadingButton>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default CustomerClaims;
export {CustomerClaimsPage};