import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import {connect} from "react-redux";
import store from "../../redux/store";
import {MODAL_CLOSE_ALERT} from "../../redux/type";
import InputAdornment from "@mui/material/InputAdornment";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import server from "../../server";
import {ClickAwayListener, MenuList, Popper} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {AxiosDefaultRequest} from "../../components/default_request";
// import {uploadShippingFee} from "../../redux/actions/RaterAction";
import APICircularProgress from "../../components/APICircularProgress";
import {number} from "prop-types";

const menuItemStyle = {
    padding: '8px 16px',
    fontSize: '0.9rem',
    borderBottom: '1px solid #eee'
};

export default function UploadZone({setSnackbar, SetApiLoading, service_name=null, year=null, quarter=null}) {
    const [open, SetOpen] = React.useState(false)
    const [upload_file, SetUploadFile] = React.useState(null)
    const [upload_data, SetUploadData] = React.useState({
        service_name: service_name?service_name:'',
        shipper_zip: '',
        year: year?year:2024,
        quarter: quarter?quarter:1,
        region: ''
    })
    const [year_error, SetYearError] = React.useState(false)
    const [shippter_zip_error, SetShippterZipError] = React.useState(false)
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         open: false,
    //         template_open: false,
    //         upload_shipping_fee_file: null,
    //         popperPosition: {}
    //     }
    //     this.anchorRef = React.createRef();
    // }

    const Close = () => {
        SetOpen(false)
    }

    // handleToggle = () => {
    //     if(this.anchorRef.current) {
    //         const rect = this.anchorRef.current.getBoundingClientRect();
    //         const top = rect.bottom + window.scrollY;
    //         const left = rect.left + window.scrollX;
    //
    //         this.setState({
    //             template_open: true,
    //             popperPosition: { top, left }
    //         });
    //     }
    // };

    // handleClose = (event) => {
    //     console.log('12345')
    //     if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
    //         return;
    //     }
    //     this.setState({ template_open: false });
    // };

    const downloadTemplate = (filename) => {
        let a = document.createElement('a');
        a.target = "_blank";
        if (server.test) {
            a.href = server.test_host + ":" + server.post + '/DownloadTemplate?file=' + filename + '.xlsx'
        } else {
            a.href = server.host + '?file=' + filename + '.xlsx'
        }
        a.click();
    }
    const Open = () => {
        SetOpen(true)
    }
    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData(e.currentTarget);

        // this.props.uploadShippingFee(this.state.upload_shipping_fee_file, data.get('upload_shipping_fee_file').name, this.props.search.id)

        if (upload_data.shipper_zip && upload_data.region && upload_data.year && upload_data.quarter && upload_data.service_name && upload_data.shipper_zip && upload_file) {
            SetYearError(false)
            SetShippterZipError(false)
            if (isNaN(upload_data.year) || upload_data.year < 1000) {
                SetYearError(true)
                // setSnackbar({open: true, message: "请输入一个正确的year！", severity: 'error'})
            }
            else if (isNaN(upload_data.shipper_zip) || upload_data.shipper_zip < 10000) {
                SetShippterZipError(true)
                // setSnackbar({open: true, message: "请输入一个正确的shipper_zip！", severity: 'error'})
            }
            else {
                SetApiLoading(APICircularProgress('success'))

                AxiosDefaultRequest({
                    url: server.zone_list,
                    method: 'POST',
                    data: {
                        file_name: data.get("upload_file").name,
                        zone_file: upload_file,
                        ...upload_data
                    },
                    success: res => {
                        let result = res.data;
                        if (result.status) {
                            // result['request'] = true
                            // result['level'] = 'success'
                            // result['title'] = 'SUCCESS'
                            // result['message'] = result['message']
                            setSnackbar({open: true, message: result['message'], severity: 'success'})
                            // dispatch({type: UPLOAD_INVOICE_SUCCESS, payload: result})
                            SetApiLoading(null)
                            SetOpen(false)
                        } else {
                            // result['request'] = false
                            // result['level'] = 'error'
                            // result['title'] = 'FAILED'
                            // result['message'] = result['message']
                            setSnackbar({open: true, message: result['message'], severity: 'error'})
                            SetApiLoading(null)
                            SetOpen(false)
                            // dispatch({type: UPLOAD_INVOICE_ERR, payload: result})
                        }


                    }, err: res => {
                        SetApiLoading(null)
                        SetOpen(false)
                        // dispatch({type: UPLOAD_INVOICE_ERR, payload: result})
                    }
                })
            }
        }
        else {

            setSnackbar({open: true, message: "缺少必要参数！", severity: 'error'})
        }


    }
    // closeAlert = () => {
    //     store.dispatch({type: MODAL_CLOSE_ALERT})
    // }
    const ChangeFile = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            SetUploadFile(event.target.result)
        };
    }
    const handleChange = (event) => {
        const {name, value} = event.target;

        SetUploadData((prev) => ({
            ...prev,
            [name]: value,
        }));

    };

    return (
        <span>
            <Button sx={{textTransform: 'none'}} startIcon={<FileUploadOutlinedIcon/>} onClick={() => {
                Open()
            }}>新增分区信息</Button>
            {/*<Popper*/}
            {/*    style={{*/}
            {/*        zIndex: 9999,*/}
            {/*        backgroundColor: '#fff',*/}
            {/*        position: 'absolute',*/}
            {/*        top: `${this.state.popperPosition.top}px`,*/}
            {/*        left: `${this.state.popperPosition.left}px`,*/}
            {/*        boxShadow: '0 3px 10px rgba(0,0,0,0.2)',*/}
            {/*        borderRadius: '5px',*/}
            {/*        overflow: 'hidden',*/}
            {/*        // width: '200px'*/}
            {/*    }}*/}
            {/*    open={this.state.template_open}*/}
            {/*    anchorEl={this.anchorRef.current} placement="bottom-start" transition>*/}
            {/*    {({ TransitionProps, placement }) => (*/}
            {/*        <ClickAwayListener onClickAway={this.handleClose}>*/}
            {/*            <MenuList>*/}
            {/*                <MenuItem style={menuItemStyle} onClick={(e) => {this.downloadTemplate('Ground与Home')}}>Ground和Home</MenuItem>*/}
            {/*                <MenuItem style={menuItemStyle} onClick={(e) => {this.downloadTemplate('MWT')}}>MWT</MenuItem>*/}
            {/*                <MenuItem style={menuItemStyle} onClick={(e) => {this.downloadTemplate('SMART_POST')}}>SMART POST</MenuItem>*/}
            {/*                <MenuItem style={menuItemStyle} onClick={(e) => {this.downloadTemplate('FEDEX_FREIGHT_ECONOMY')}}>FEDEX_FREIGHT_ECONOMY</MenuItem>*/}
            {/*            </MenuList>*/}
            {/*        </ClickAwayListener>*/}
            {/*    )}*/}
            {/*</Popper>*/}
            <Dialog disableEscapeKeyDown open={open} onClose={() => {
                Close()
            }}>
                    <DialogTitle>新增分区信息</DialogTitle>
                    <DialogContent>
                        <Box component="form" onSubmit={handleSubmit} sx={{display: 'inline-block', flexWrap: 'wrap', width: 500, height: 550}}>
                            <FormControl sx={{ml:"8%", width: "80%"}}>
                                <TextField
                                    id="upload_file"
                                    name="upload_file"
                                    type='file'
                                    label="请上传一个分区表(xlsx格式)"
                                    onChange={(e) => {
                                        ChangeFile(e)
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FileUploadIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    required
                                />
                            </FormControl>
                            {!service_name &&
                            <FormControl sx={{ml:"8%", width: "80%"}}>
                                <TextField
                                    id="service_name"
                                    name="service_name"
                                    type='text'
                                    label="service_name"
                                    value={upload_data.service_name}
                                    variant="standard"
                                    onChange={handleChange}
                                    required
                                />
                            </FormControl>
                            }
                            {!year &&
                            <FormControl sx={{ml:"8%", width: "80%"}}>
                                <TextField
                                    id="year"
                                    name="year"
                                    type='number'
                                    error={year_error}
                                    label="year"
                                    value={upload_data.year}
                                    variant="standard"
                                    onChange={handleChange}
                                    required
                                />
                            </FormControl>
                            }
                            {!quarter &&
                            <FormControl sx={{ml:"8%", width: "80%"}}>
                                <TextField
                                    id="quarter"
                                    name="quarter"
                                    type='number'
                                    label="quarter"
                                    value={upload_data.quarter}
                                    variant="standard"
                                    onChange={handleChange}
                                    required
                                />
                            </FormControl>}
                            <FormControl sx={{ml:"8%", width: "80%"}}>
                                <TextField
                                    id="region"
                                    name="region"
                                    type='text'
                                    label="region"
                                    value={upload_data.region}
                                    variant="standard"
                                    onChange={handleChange}
                                    required
                                />
                            </FormControl>
                            <FormControl sx={{ml:"8%", width: "80%"}}>
                                <TextField
                                    error={shippter_zip_error}
                                    id="shipper_zip"
                                    name="shipper_zip"
                                    type='number'
                                    minLength={4}
                                    label="shipper_zip"
                                    value={upload_data.shipper_zip}
                                    variant="standard"
                                    onChange={handleChange}
                                    required

                                />
                            </FormControl>
                            <DialogActions>
                                <Button
                                    sx={{mt: 3, mb: 2, ml: 26, whiteSpace: 'nowrap', minWidth: 'auto'}}
                                    // ref={this.anchorRef}
                                    // onMouseOver={this.handleToggle}
                                    variant={"contained"}
                                    onClick={(e) => downloadTemplate("ZONE")}
                                >
                                    下载模板文件
                                </Button>

                                <Button fullWidth variant="contained" sx={{mt: 3, mb: 2}} onClick={() => {
                                    Close()
                                }}>取消</Button>
                                <Button fullWidth type='submit' variant="contained" sx={{mt: 3, mb: 2}}>上传</Button>
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>
        </span>
    );

}

// 更新就渲染
// const mapStateToProps = (state) => ({
//     isAuthenticated: state.auth.isAuthenticated,
//     search: state.RaterReducer.shipping_fee_search,
//     user: state.auth.user
// });
//
// export default connect(mapStateToProps, {uploadShippingFee})(UploadZone);