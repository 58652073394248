import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar} from '@mui/material';

import Grid from "@mui/material/Grid";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Alert from "@mui/material/Alert";
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import {useDispatch} from "react-redux";
import {RenderSelectField} from "../../../../components/renderField";

const CreateWarehouseAndAccount= ({ onSave }) => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user_options, setUserOptions] = useState([])
    const [user_info, setUserInfo] = useState({})
    const [formData, setFormData] = useState({
        user: '',
        supplier: '',
        region: 'EWR',
        name: '',
        account_user: '---',
        account_kwargs_1: '---',
        account_kwargs_2: '---',
        account_kwargs_3: '---',
        account_kwargs_4: '---',
        account_kwargs_5: '---',
        account_kwargs_6: '---',
        account_kwargs_7: '---',
        isDebug: 0,
        isDefault: 0,
        isSystemDefault: 0,
        optional: '{}',

    });
    const [error, setError] = useState({
        user: false,
        supplier: false,
        name: false
    })
    const onClose = () => setOpenCreateDialog(false);
    const onOpen = () => setOpenCreateDialog(true);
    const [supplier_options, setSupplierOptions] = useState([])
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.logistics_supplier_list}?page=0&size=100`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        setSupplierOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        const fetchUserData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: server.user_list_url,
                    method: 'GET',
                    success: (res) => {
                        let UserList = res.data.UserList
                        let user_opt = []
                        let info = {}
                        for (let [key, value] of Object.entries(UserList)) {
                            user_opt.push({id: key, username: value})
                            info[key] = value
                        }
                        setUserOptions(user_opt)
                        setUserInfo(info)
                    }
                })
            } catch (error) {
                console.error('请求异常用户列表异常', error);
            }
        }
        fetchData();
        fetchUserData()
    }, [dispatch]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    const handleFieldChange = (e, value, key, value_name) => {
        if(key === "user"){
            let Name
            if(value && value[value_name] === "1"){
                Name = "System"
            }
            else {
                if(value){
                    Name = user_info[value[value_name]].split("(")[0]
                }

            }
            setFormData((prev) => ({
                ...prev,
                [key]: value?value[value_name]:'',
                name: Name
            }));
        }
        else {
            setFormData(prevState => ({
                ...prevState,
                [key]: value?value[value_name]:''
            }));
        }

    }
    const handleChange = (event) => {
        const { name, value } = event.target;


        if (name==="user"){
            let Name
            if (value === "1"){
                Name = "System"
            }
            else {
                Name = user_info[value].split("(")[0]
            }
            setFormData((prev) => ({
                ...prev,
                [name]: value,
                name: Name
            }));
        }
        else if(name.includes("is")){
            setFormData((prev) => ({
                ...prev,
                [name]: parseInt(value, 10),
            }));
        }
        else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleSave = async () => {
        let data = formData
        Object.entries(formData).forEach(([key, value]) => {
            if(value === 0 || value === '' || value === '---'){
                delete data[key]
            }
        });

        setError({
            user: false,
            supplier: false,
            name: false
        })
        if (formData.user && formData.supplier && formData.name) {

            setLoading(true);
            try {

                const result = await onSave(formData, formData.id, 'POST');
                if (result.status === true) {
                    setFormData({
                        user: '',
                        supplier: '',
                        region: 'EWR',
                        name: '',
                        account_user: '---',
                        account_kwargs_1: '---',
                        account_kwargs_2: '---',
                        account_kwargs_3: '---',
                        account_kwargs_4: '---',
                        account_kwargs_5: '---',
                        account_kwargs_6: '---',
                        account_kwargs_7: '---',
                        isDebug: 0,
                        isDefault: 0,
                        isSystemDefault: 0,
                        optional: '{}',
                    })
                    // 操作成功，显示成功消息
                    // setSnackbar({open: true, message: '保存成功！', severity: 'success'});
                }
            } catch (error) {
                console.error("保存失败", error);
                // 操作失败，显示错误消息
                // setSnackbar({ open: true, message: '保存失败,请重试.', severity: 'error' });
            } finally {
                setLoading(false);
                onClose(); // 保存操作完成后关闭对话框
            }
        }
        else {
            for(let key in error){
                if(!formData[key]){
                    setError((prev) => ({
                        ...prev,
                        [key]: true,
                    }));
                }
            }
        }

    };
    return (
        <>

            <Button sx={{textTransform: 'none'}} startIcon={<WarehouseIcon/>} onClick={() => {onOpen()}}>
                新增仓库和账号
            </Button>
            <Dialog open={openCreateDialog} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>新增仓库和管理记录</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>

                            {RenderSelectField({
                                idAname: "user", label: "可用用户",
                                value: formData.user,
                                select_options: user_options,
                                v: "id",
                                value_name: "username",
                                sx: {width: 350},
                                onChangeHandler: (e, value) => {
                                    handleFieldChange(e, value, "user", "id")
                                },
                                required: true,
                                error: error.user
                            })}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {RenderSelectField({
                                idAname: "supplier", label: "渠道商",
                                value: formData.supplier,
                                select_options: supplier_options,
                                v: "id",
                                value_name: "name",
                                sx: {width: 350},
                                onChangeHandler: (e, value) => {
                                    handleFieldChange(e, value, "supplier", "id")
                                },
                                required: true,
                                error: error.supplier
                            })}

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="region"
                                label="口岸"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.region}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="name"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                error={error.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_user"
                                label="账号名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_user}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_kwargs_1"
                                label="参数1"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_1}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_kwargs_2"
                                label="参数2"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_2}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_kwargs_3"
                                label="参数3"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_3}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_kwargs_4"
                                label="参数4"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_4}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_kwargs_5"
                                label="参数5"
                                type="text"
                                fullWidth
                                variant="standard"
                                defaultValue='---'
                                value={formData.account_kwargs_5}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_kwargs_6"
                                label="参数6"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_6}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="account_kwargs_7"
                                label="参数7"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_7}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="isDefault"
                                label="是否默认账号"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.isDefault}
                                onChange={handleChange}
                            >
                                <MenuItem key="isDefault_0" value="0">
                                    否
                                </MenuItem>
                                <MenuItem key="isDefault_1" value="1">
                                    是
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="isDebug"
                                label="是否测试账号"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.isDebug}
                                onChange={handleChange}
                            >
                                <MenuItem key="isDebug_0" value="0">
                                    否
                                </MenuItem>
                                <MenuItem key="isDebug_1" value="1">
                                    是
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="isSystemDefault"
                                label="是否系统默认账号"
                                type="text"
                                fullWidth
                                variant="standard"
                                select
                                value={formData.isSystemDefault}
                                onChange={handleChange}
                            >
                                <MenuItem key="isSystemDefault_0" value="0">
                                    否
                                </MenuItem>
                                <MenuItem key="isSystemDefault_1" value="1">
                                    是
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="optional"
                                label="其他参数"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.optional}
                                onChange={handleChange}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant={"contained"}>取消</Button>
                    <Button loading={loading} onClick={handleSave}>保存</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>

    );
};

export default CreateWarehouseAndAccount;
