let server = {
    "test": false,
    "host": "http://nserver.awesung-api.com",
    "test_host": "http://localhost",
    "post": "8002",
    'ship_url': '/ship_list/',
    'bill_url': '/billdata_list/',
    'user_url': '/account_list/',
    'user_list_url': '/getUserList/',
    'dimension_list_url': '/dimension_list/',
    'warehouse_list_url': '/warehouse/list/',
    'new_warehouse_list_url': '/warehouse/new_list/',
    'add_credit_url': '/add_credit/',
    'upload_invoice_url': '/uploadInvoice/',
    'get_label_url': '/uploadGetShip/',
    'async_upload_invoice_url': '/SyncUploadInvoice/',
    'upload_claim_url': '/UploadClaim/',
    'update_claim_url': '/UpdateClaim/',
    'verify_claim_url': '/VerifyClaim/',
    'revoke_claim_url': '/RevokeClaim/',
    'confirm_refund_url': '/ConfirmRefundClaim/',
    'ReStoreBill_url': '/ReStoreBill/',
    'CellEditShippingFee_url': '/CellEditShippingFee/',
    'CellEditOfferSheet_url': '/CellEditOfferSheet/',
    'download_get_ship_url': '/DownloadGetShip/',  // 获取
    'download_ship_url': '/DownloadShip/',  // 下载
    'preview_ship_url': '/previewShip/',
    'delete_record_url': '/DeleteRecord/',
    'record_url': '/record_list/',
    'rater_url': '/rater_list/',
    'shipping_fee_url': '/shipping_fee_list/',
    'upload_shipping_fee_url': '/UploadShippingFee/',
    'offer_sheet_url': '/offer_sheet_list/',
    "offer_sheet_update_url": "/offer_sheet_update/",
    'void_ship_url': '/art_void/',
    'get_pod_url': '/exportPod/',
    'replenish_url': '/replenish/',
    'replenish_master_track_url': '/replenish_master_track/',
    'verify_credit_url': '/verify_credit/',
    "surcharge_list": "/surcharge_list/",
    "get_offer_sheet_list": "/get_offer_sheet_list/",
    "batch_query_trace_numbers_list": "/batch_query_trace_numbers/",
    "logistics_supplier_list": "/logistic_supplier/",
    "logistics_service_list": "/logistic_supplier/logistics_service/",
    "select_logistics_service_list": "/logistic_supplier/select_logistics_service/",
    "service_and_user_list": "/logistic_supplier/service_and_user/",
    "service_and_special_list": "/logistic_supplier/service_and_special/",
    "user_package_special_service_list": "/logistic_supplier/user_package_special_services/",
    "service_and_warehouse_list": "/logistic_supplier/service_and_warehouse/",
    "warehouse_and_account": "/logistic_supplier/warehouse_and_account/",

    "fba_address_list": "/fba_address/",
    'internal_claims_list': '/claims/internal_claims/',
    'customer_claims_list': '/claims/customer_claims/',

    'claims_user_uid_list': '/get_user_uid/',
    'page_special_services': '/get_page_special_services/',

    'service_type': '/service_type/',
    'freight_ship': '/V1/freight-ship',
    'warehouse_service': '/warehouse_service/',
    "freight_shipment_url": "/freight_shipment_api/",
    "pickup_url": "/pickup/",
    'account': '/account/',
    'zone_list': '/zone_list/',
    'cancel_credit': '/cancel_credit/',
    'backend_ship_record_url': '/backend_ship_record/',
    "FuelSurcharge": "/fuel_surcharge/",
    "batch_void_shipment": "/batch_void_shipment/",
}
export default server
