import * as React from 'react';
import Button from '@mui/material/Button';
import {connect} from "react-redux";
import {downloadFile, DeleteRecord, LoadReceiptPage} from "../../redux/actions/modal";
import Modal from "@mui/material/Modal";
import store from "../../redux/store";
import {
    ADD_CREDIT_RECORD_SEARCH_STOP,
    EXPORT_PAGE_SEARCH_UPDATE, RECEIPT_OPEN, RECEIPT_PAGE_SEARCH_STOP, RECEIPT_PAGE_SEARCH_UPDATE
} from "../../redux/type";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {AddCreditSearchTable} from "../../components/searchTable";
import ListAltIcon from '@mui/icons-material/ListAlt';
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import APIToolBar from "../../components/APIToolBar";
import SetColumns from "../../components/SetColumns";
import SetVisibility from "../../components/SetVisibility";
import {GridActionsCellItem} from "@mui/x-data-grid";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import '../styles.scss'


const localStorageKey = 'ExportPageModalColumns';
const localStorageVisibilityKey = 'ExportPageVisibilityModalColumns';
const status_chinese = {
    "initializing": "初始化", "Processing": "处理中", "Complete": "完成", "Error": "错误", "Reset": "重置"
}
class ReceiptPage extends React.Component {
    constructor(props) {
        super(props);
        // user, type
        this.state = {
            // open: this.props.open,
            Visibility: this.setVisibility,
            type: props.type
        }
        store.dispatch({
            type: RECEIPT_PAGE_SEARCH_UPDATE, payload: {
                "record_type": this.state.type
            }
        })
    }
    columns: GridColDef[] = [
        {id: 0, field: "id", headerName: "Id"},
        // {id: 1, field: "record_type", headerName: "", width: 150},
        {id: 1, field: "import_name", headerName: "名称", width: 250},
        {
            id: 2,
            field: "create_time",
            headerName: "创建时间",
            width: 200,
            renderCell: (params) => {
                // 假设params.value是一个符合ISO 8601的UTC时间字符串
                // 创建一个新的Date对象，它将自动转换为浏览器所在时区的本地时间
                if (params.value){
                    const utcTimeString = params.value + 'Z';
                    const date = new Date(utcTimeString);
                    return date.toLocaleString();
                }
            }
        },
        {
            id: 3, field: "record_status", headerName: "record_status", width: 150, renderCell: (params) => {
                let chinese_status = status_chinese[params.row.record_status]
                return (
                    <div className={`AdminCellWithStatus ${params.row.record_status}`}>
                        {chinese_status}
                    </div>
                )
            }
        },


        {id: 4, field: "error_message", headerName: "错误信息/备注信息", width: 200},
        {
            id: 5, field: 'actions', type: 'actions', headerName: "Actions", renderCell: (params) => {
                return [
                    <span key={params.row.id}>
                    {/*<GridActionsCellItem*/}
                        {/*    icon={<DeleteIcon/>}*/}
                        {/*    label="Delete"*/}
                        {/*    color="primary"*/}
                        {/*    sx={{mr: 1}}*/}
                        {/*    onClick={() => {*/}
                        {/*        this.props.DeleteRecord(params.row.id, this.props.data)*/}
                        {/*    }}*/}
                        {/*    disabled={params.row.record_status === 'Complete'}*/}
                        {/*/>*/}
                        <GridActionsCellItem
                            icon={<DownloadIcon/>}
                            label="Download"
                            onClick={() => {
                                this.props.downloadFile(params.row.download_file_name)
                            }}
                            color="primary"
                            disabled={params.row.record_status !== 'Complete'}
                        />
                </span>
                ];
            }
        }
    ]

    columnVisibilityModel = { // 控制是否展示列
        id: false,
    }

    set_columns = SetColumns(localStorageKey, this.columns);
    setVisibility = SetVisibility(localStorageVisibilityKey, this.columnVisibilityModel);

    ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        // 获取columns
        // console.log(set_columns)
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(localStorageKey, JSON.stringify(this.set_columns))
        // oldIndex > targetIndex
        // targetIndex < oldIndex
    }
    ChangeColumnVisibilityModel = (modal) => {
        localStorage.setItem(localStorageVisibilityKey, JSON.stringify(modal))
        this.setState({Visibility: modal})
    }
    useQuery = (page) => {
        this.props.LoadReceiptPage((page * this.props.pageSize), ((page + 1) * this.props.pageSize),
            page, null, this.props.search)
    }
    changePageSize = (pageSize) => {
        setTimeout(() => {
            this.props.LoadExportPage((this.props.page * pageSize), ((this.props.page + 1) * pageSize),
                null, pageSize, this.props.search)
        }, 500);
    }
    Close = () => {
        store.dispatch({type: RECEIPT_OPEN})
        // this.setState({open: !this.props.open})
    }
    Open = () => {
        this.props.LoadReceiptPage(this.props.page, this.props.pageSize, null, null, this.props.search)
        store.dispatch({type: RECEIPT_OPEN})
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (store.getState().modal.exportPage_stop && nextProps.search && !this.props.isLoading) {  // search
            store.dispatch({type: RECEIPT_PAGE_SEARCH_STOP})
            this.props.LoadExportPage(this.props.page, this.props.pageSize, null,
                null, nextProps.search);
        }
    }
    // user_id, create_time,record_type,import_name,error_message
    render() {
        return (
            <span>
                <Button
                    type="button"
                    variant="contained"
                    id="DownloadSearch"
                    startIcon={<ListAltIcon/>}
                    sx={{textTransform: 'none', mr: '0%'}}
                    onClick={() => {
                        this.Open()
                    }}
                >下载回执结果
                </Button>
                <Modal
                    open={this.props.open}
                    onClose={() => {
                        this.Close()
                    }}
                    aria-labelledby="add-credit-title"
                    aria-describedby="add-credit-description"
                    sx={{
                        display: 'flex',          // 设置 Modal 为 Flex 容器
                        alignItems: 'center',     // 垂直居中
                        justifyContent: 'center', // 水平居中
                    }}
                >
                <Container sx={{mt: 4, mb: 4}}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                          <Paper
                              sx={{
                                  p: 2,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: 800,
                              }}
                          >
                          <DataGridPro
                              rows={this.props.data}
                              sx={{minHeight: 750, overflow: 'auto', display: 'block'}}
                              columns={this.set_columns}
                              pageSize={this.props.pageSize}
                              rowsPerPageOptions={[20, 30, 50, 100, 200, 500]}
                              autoHeight={true}
                              loading={this.props.isLoading}
                              rowCount={this.props.rowCount}
                              paginationMode='server'
                              columnVisibilityModel={this.state.Visibility}
                              onColumnVisibilityModelChange={(newModel) =>
                                  this.ChangeColumnVisibilityModel(newModel)
                              }
                              initialState={
                                  {pinnedColumns: {right: ['actions']}}
                              }
                              rowReordering={false}
                              onColumnOrderChange={(e) => {
                                  this.ColumnOrderChange(e)
                              }}
                              components={{
                                  LoadingOverlay: LinearProgress,
                                  NoRowsOverlay: CustomNoRowsOverlay,
                                  Toolbar: APIToolBar
                              }}
                              onPageChange={(page) => this.useQuery(page)}
                              onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                              pagination={true}
                          />
                      </Paper>
                    </Grid>
                  </Grid>
                </Container>

              </Modal>
            </span>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isLoading: state.modal.isLoading,
    pageSize: state.modal.ReceiptPage_pageSize,
    page: state.modal.ReceiptPage_page,
    rowCount: state.modal.ReceiptPage_rowCount,
    data: state.modal.ReceiptPage_data,
    user: state.auth.user,
    search: state.modal.ReceiptPage_search,
    open: state.indexReducer.ReceiptOpen,
});

export default connect(mapStateToProps, {LoadReceiptPage, downloadFile, DeleteRecord})(ReceiptPage);








